<template>
  <div class="admin-add-sales-import">
    <error :err.sync="error" />
    <template v-if="loading">
      <template v-if="progress < 100">
        <b-progress
          :value="progress"
          show-progress
          animated
          height="1.6rem"
        ></b-progress>
        <h3 class="text-center mt-5">
          {{ $t("uploading") }}
        </h3>
      </template>
      <template v-else>
        <spinner />
        <h3 class="text-center mt-5">
          {{ $t("processing") }}
        </h3>
      </template>
    </template>
    <form-generator
      :elements="elements"
      :data="isbn"
      :handleUpdate="({ key }, v) => (isbn[key] = v)"
      v-if="render && !loading"
    />
  </div>
</template>

<script>
import isbnSchema from "@/validation-schemas/isbns";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

function initialState() {
  return {
    isbn: {
      filename: null,
      file: null,
      type: "epub",
      book_id: null
    },
    loading: false,
    error: null,
    render: true,
    progress: 0
  };
}

export default {
  components: { FormGenerator },
  name: "admin-add-sales-import",
  data() {
    return initialState();
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels", {
      all: true
    });
    this.$store.dispatch("saleImports/getProcessorTypes");
    window.onbeforeunload = this.beforeUnload;
  },
  computed: {
    ...mapState("ancillaries", ["allSalesChannels"]),
    ...mapState("saleImports", ["processorTypes"]),
    elements() {
      return [
        {
          type: "paragraph",
          text:
            "Please upload an Excel file containing a single column of ISBNs that you want to add to the system. ISBNs already present will be ignored."
        },
        {
          type: "dropzone",
          events: {
            input: files => {
              if (files.length) {
                this.isbn.file = files[0];
              } else {
                this.isbn.file = null;
              }
            }
          }
        },
        {
          key: "type",
          label: "isbn-type",
          type: "select",
          attrs: {
            options: ["epub", "print"].map(i => ({
              text: this.$t(i),
              value: i
            }))
          }
        },
        {
          text: "import",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    beforeUnload() {
      if (this.loading && this.progress === 100) {
        return "";
      }
    },
    async add() {
      this.error = null;
      const { isbn } = this;
      const validation = isbnSchema.validate(isbn, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("isbns/create", {
            isbn,
            onUploadProgress: e => {
              this.progress = (e.loaded / e.total) * 100;
            }
          });

          // Reset data
          this.$emit("added", res.data);
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    "isbn.sales_channel"(channel) {
      this.isbn.sales_channel_id = channel ? channel.id : null;
    }
  }
};
</script>

<style></style>
